.Rating-count{
    position: absolute;
    font-size: 9px;
    color: var(--default-color-white) !important;
    background-color: var(--secondary-color);
    /* padding: 5px 5px; */
    border-radius: 100px;
    font-weight: bold;
    width: 20px;
    height: 20px;
    padding-top: 3px;
    text-align: center;
}