.sidebar {
    z-index: 100;
    /* padding: 48px 0 0; */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, .1);
    background: var(--default-color-white);
    opacity: .9;
    /* height: 100vh; */
}

.sidebar .nav-item {
    width: 100%
}

.sidebar .nav-item .nav-link {
    padding: 15px 40px !important;
    color: var(--theme-color);
    font-weight: 600;
    font-size: 14px;
}

.sidebar .nav-item .nav-link:hover {
    background: var(--secondary-color);
    color: var(--default-color-white)
}

.myProfileActive {
    background: var(--secondary-color);
    color: var(--default-color-white) !important;
}