.Error404 {
    font-size: 28vh;
    color: var(--default-color-white);
}
.ErrorContent {
    font-size: 3.75rem;
    opacity: .3;
    color: var(--default-color-white);
}
.errorPageSection {
    background: var(--secondary-color);
    height: 100vh;
}
.btnGoToHome {
    background: var(--theme-color) !important;
    border-color: var(--theme-color) !important;
}