.statusActive {
    color: var(--btn-success-color);
    font-size: 14px;
    font-weight: 600;
}

.statusInactive {
    color: var(--btn-danger-color);
    font-size: 14px;
    font-weight: 600;
}