.popular-event-section h2 {
    color: #207490;
}

.popular-event-section .content-popular-event {
    color: #A2BBCC;
    font-weight: 400;
    margin: 0;
    font-size: 14px;
}

.popular-event-section .card {
    margin-bottom: 1rem;
    min-height: 140px;
    border: none;
    background-color: #207490;
    border-radius: 12px;
}

.popular-event-section .card-body {
    color: #ffffff;
}

.popular-event-section .card-body .card-title {
    width: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    padding-top: 1rem;
}

.popular-event-section .card-body .card-text span {
    font-weight: 400;
    font-size: 14px;
}

.popular-event-section .card-body .card-text {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 1rem;
}

@media only screen and (min-width: 300px) and (max-width: 767px) {
    .popular-event-card .col-sm-3 {
        display: contents;
    }

    .popular-event-section .card-body .card-text {
        flex-direction: column;
    }

    /* .popular-event-section .card {
        width: calc(90% / 2);
        margin: 0.5rem;
    } */
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .popular-event-section .card-body .card-text {
        flex-direction: column;
    }
}