.ticketType {
    padding-left: 15px;
}

.textBlackColor{
    color: black;
}
.searchResultEventName h5 {
    font-weight: 600;
    color: var(--secondary-color);
}

.searchResultEventName span {
    font-size: 14px;
    font-weight: 600;
    padding-right: 15px;
}

.divTicketImage img {
    /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    border-radius: 4px;
    width: auto;
    min-height: 120px; */
    object-fit: contain;
}

.btnBuySearchResult {
    background: var(--theme-color) !important;
    border-color: var(--theme-color) !important;
    color: var(--default-color-white) !important;
    padding: .5rem .7rem !important;
}

.btnCartSearchResult {
    background: var(--btn-blue-color) !important;
    border-color: var(--btn-blue-color) !important;
    color: var(--theme-color) !important;
    padding: .5rem .7rem !important;
    margin-bottom: 15px;
}

.btnBuySearchResult i, .btnCartSearchResult i {
    padding-right: 5px;
}

.searchResultLeftColumn div, .searchResultCenterColumn div {
    margin-bottom: 10px;
}

.searchResultCard:hover {
    box-shadow: 0px 10px 20px rgb(0,0,0,.1);
    /* cursor: pointer; */
    transition: box-shadow 500ms;
}

.searchResultLocation {
    padding-left: 21px;
}

.searchResultDate, .searchResultTeamArt {
    padding-left: 15px;
}

.btnBuySpace {
    margin: 23px auto;
}
/* ------------------------------------- Media Query ----------------------------------- */
@media only screen and (min-width: 300px) and (max-width: 768px) {
    .btnBuySpace {
        margin: 10px auto;
    }

    .btnCartSearchResult {
        margin-bottom: 0px;
        margin-right: 15px;
    }
}