/* .margin {
    margin: 65px;
} */
/* .card_class {
    margin: 26px;
    padding: 33px;
} */
/* .card_header {
    cursor: pointer;
} */
.align_props{
    float: right;
    margin-right: 0px;
}
.space_button {
    margin-right: 5px;
}
/* .card_header {
    position: relative;
}
.iconClass{
    position:absolute;
    top: 25px;
    right: 30px;
} */

/* .card_header .iconClass i::after {
    transform: rotateZ(180deg);
} */
/* .alignClass {
    float: right;
} */
/* .eventAdd {
    margin-left: 5px;
}
.padd-0 {
    padding: 0px;
} */
/* .parentAddTicket .activeFromPage {
    background: var(--secondary-color);
    color: var(--default-color-white) !important;
} */


