.roomName {
  font-weight: bold;
}

.textBlack {
  color: #000;
}

.hasUnreadMessagesActive {
  background-color: green;
  content: "";
  border-radius: 50%;
  width: 10px;
  height: 10px;
}


