.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:root {
  --body-color: #EFF4F6 !important;
}
/* Global styles for memory lane */
:root {
  --theme-color: #003d79;
  --secondary-color: #269399;
  --default-color-white: #ffffff;
  --btn-success-color: #198754;
  --btn-danger-color: #dc3545;
  --btn-blue-color: #BBDDFF;
  min-width: 360px;
}
@font-face {
  font-family: "Segoe UI";
  src: url("./font/Segoe\ UI.woff2") format("woff2");
  src: url("./font/Segoe\ UI\ Bold.woff2") format("woff2");
  font-size: medium;
  font-weight: 800;
}

@font-face {
  font-family: "Segoe UI";
  src: url("./font/Segoe-UI.woff") format("woff");
  src: url("./font/Segoe-UI-Bold.woff") format("woff");
  font-size: medium;
  font-weight: 700;
}

@font-face {
  font-family: "Segoe UI";
  src: url("./font/Segoe\ UI.ttf") format("ttf");
  src: url("./font/Segoe\ UI\ Bold.ttf") format("ttf");
  font-size: small;
  font-weight: 600;
}
@font-face {
  font-family: 'Helvetica';
  src: url('./font/Helvetica.ttf');
}
a, a:focus {
text-decoration: none !important;
outline: none;
}
body {
  padding: 0;
  margin: 0;
  background-color: var(--body-color);
  font-family: "Segoe UI","wf_SegoeUI" !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  /* font-family: Helvetica Neue, Helvetica, Arial, sans-serif !important; */
}

/* All form required field */
.requiredField:after {
  content:"*";
  color:red;
}

.text-ellipsis-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.word-break {
  word-break: break-word;
}