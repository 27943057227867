/* .tableMommentHistory thead {
    background: var(--theme-color);
    color: var(--default-color-white);
}

.tableMommentHistory {
    margin: 5rem auto !important;
}

.tableMommentHistory thead tr th {
    padding: 1.1rem !important;
} 

.mommentHistoryContainer {
    max-width: 1200px !important;
}

.tableMommentHistory tbody tr td {
    padding: 1.5rem !important;
}

.tableMommentHistory tbody tr:nth-of-type(2n+1) {
    background-color: #fff !important;
}

.tableMommentHistory tbody tr:nth-of-type(2n+2) {
    background-color: #bbddff !important;
}

.tableTeamArtist thead {
    background: var(--theme-color);
    color: var(--default-color-white);
}

.tablePosition {
    margin: 3rem 0rem;
}

.tableTeamArtist thead tr th {
    padding: 1.2rem .75rem;
}

.nav-scroller {
    position: relative;
    z-index: 2;
    height: auto;
    overflow-y: hidden;
}

.nav-scroller .nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    padding-bottom: 1rem;
    margin-top: -1px;
    overflow-x: auto;
    text-align: center;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    padding-top: 30px;
}

.navScrollContainer {
    height: 100px;
    padding: 0px !important;
    max-width: 1200px !important;
}

.navBgColor {
    background: var(--theme-color);
}

.filterNav {
    background: #000;
    padding: 9px 50px;
    border-radius: 4px;
    color: var(--default-color-white);
    font-weight: 600;
}

.nav-scroller .nav .inputSelectMomment {
    margin: 0px 15px;
    width: 200px;
}

.inputSelectMomment {
    padding: 8px 35px;
    font-weight: 600;
    background: var(--default-color-white);
    color: var(--theme-color) !important;
    border-color: var(--default-color-white);
}

.inputSelectMomment:focus {
    box-shadow: none !important;
    border-color: var(--default-color-white);
}

.collapsibleMobileModeMomments .Collapsible {
    background-color: white;
    display: none;
}

.collapsibleMobileModeMomments .Collapsible__contentInner {
    padding: 10px;
    border-bottom: 2px solid #ebebeb;
    border-top: 0;
}

.collapsibleMobileModeMomments .Collapsible__contentInner p {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px;
}

.collapsibleMobileModeMomments .Collapsible__contentInner p:last-child {
    margin-bottom: 0;
}

.collapsibleMobileModeMomments .Collapsible__trigger {
    display: block;
    font-weight: 400;
    text-decoration: none;
    color: #333333;
    position: relative;
    padding: 20px 10px;
    background: var(--theme-color);
    color: var(--default-color-white);
    cursor: pointer;
    font-weight: bold;
}

.collapsibleMobileModeMomments .Collapsible__trigger:after {
    font-family: 'FontAwesome';
    content: '\f107';
    position: absolute;
    right: 10px;
    top: 25px;
    display: block;
    transition: transform 300ms;
}

.collapsibleMobileModeMomments .Collapsible__trigger.is-open:after {
    transform: rotateZ(180deg);
}

.selectTeamArtResponsive {
    margin: 15px 20px;
}
*/
/* ---------------------------------------------- media query ---------------------------------------- */
/* @media only screen and (min-width: 300px) and (max-width: 768px) {
    .tableMommentHistory tbody tr td {
        padding: .8rem !important;
    }
    .collapsibleMobileModeMomments .Collapsible {
        display: block !important;
    }
    .navBgColor {
        display: none;
    }
}  */

.momentCardContainer {
    margin: 3rem auto;
    padding: 0px !important;
    max-width: 1200px !important;
}