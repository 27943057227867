.registerPageSection {
    background: var(--secondary-color);
    height: 115vh;
    position: relative;
}

.btnRegister {
    background: var(--theme-color) !important;
    border-color: var(--theme-color) !important;
    width: 100%;
}

.registerFormSection {
    width: 30rem;
    background: var(--default-color-white);
    padding: 26px;
    box-shadow: 0px 0px 20px 0px rgba(73, 73, 73, 0.8);
    -webkit-box-shadow: 0px 0px 20px 0px rgba(73, 73, 73, 0.8);
    -moz-box-shadow: 0px 0px 20px 0px rgba(73, 73, 73, 0.8);
    border-radius: 4px;
    border: 10px solid #f0f0f0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.registerTitleDiv h5 {
    margin: 0px;
    font-size: 1rem;
    font-weight: 600;
    color: var(--theme-color);
}

.loginAccount span {
    line-height: 40px;
}

.loginAccount a, .loginAccount a:hover, .backHome a, .backHome a:hover {
    color: var(--theme-color);
}

.loginLogo img {
    width: 100px;
}

/* ---------------------------------------------- media query ---------------------------------------- */
@media only screen and (min-width: 300px) and (max-width: 768px) {
    .registerFormSection {
        width: 90%;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .registerFormSection {
        width: 90%;
    }
}