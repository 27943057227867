
.textBlackColor{
    color: black;
}
.searchResultEventName h6 {
    font-weight: 600;
    color: var(--secondary-color);
}

.searchResultEventName span {
    font-size: 14px;
    font-weight: 600;
    padding-right: 15px;
}

.divTicketImage img {
    object-fit: contain;
}
