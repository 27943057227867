/* .selectTeamArtistResponsive {
    margin: 15px auto;
}

.nav-scroller {
    position: relative;
    z-index: 2;
    height: auto;
    overflow-y: hidden;
}

.nav-scroller .nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    padding-bottom: 1rem;
    margin-top: -1px;
    overflow-x: auto;
    text-align: center;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    padding-top: 30px;
}

.nav-scroller .nav .inputSelectTeam {
    margin: 0px 15px;
    width: 200px;
}

.navTeamArtistScrollContainer {
    height: 100px;
    padding: 0px !important;
    max-width: 1200px !important;
}

.navTeamArtistBgColor {
    background: var(--theme-color);
}

.filterNavTeam {
    background: #000;
    padding: 9px 50px;
    border-radius: 4px;
    color: var(--default-color-white);
    font-weight: 600;
}

.selectTeam {
    padding: 8px 35px;
    font-weight: 600;
    background: var(--default-color-white);
    color: var(--theme-color) !important;
    border-color: var(--default-color-white);
}

.selectTeam:focus {
    box-shadow: none !important;
    border-color: var(--default-color-white);
} */

/* .basic-single {
    width: 200px;
} */

/* ---------------------------------------------- media query ---------------------------------------- */
@media only screen and (min-width: 300px) and (max-width: 768px) {
    .basic-single {
        width: 100% !important;
        margin: 15px auto;
    }
}

@media only screen and (max-width: 1280px) {
    .basic-single {
        width: 140px;
    }
}