.tableZoomResult tbody tr:nth-of-type(2n+1) {
    background-color: #fff !important;
}

.tableZoomResult tbody tr:nth-of-type(2n+2) {
    background-color: #fff !important;
}

.tableZoomResult thead {
    background: var(--theme-color);
    color: var(--default-color-white);
}

.tableZoomResult {
    margin: 50px auto !important;
}

.zoomResultTicket img {
    width: 389px;
    height: 674px;
}

.btnPrevious {
    background: var(--secondary-color) !important;
    border-color: var(--secondary-color) !important;
    margin-top: 30px !important;
}