.carousel-section h2 {
    color: #207490;
}

.carousel-section .content-carousel-section {
    color: #A2BBCC;
    font-weight: 400;
}

.carouselCard-section .card {
    border-radius: 12px;
    padding: 1rem;
    min-height: 380px;
}

.carouselCard-section .card .card-ticket-title, .carouselCard-section .card .ticket-title, .carouselCard-section .card .ticket-date {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
}
.carouselCard-section .card .card-ticket-title h6 {
    color: #367D98;
    font-weight: 600;
    width: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.owl-theme .owl-controls .owl-page span {
    background: none repeat scroll 0 0 #869791;
    border-radius: 20px;
    display: block;
    height: 12px;
    margin: 5px 7px;
    opacity: 0.5;
    width: 12px;
}

.owl-theme .owl-dots .owl-dot.active span {
    width: 40px;
    background-color: #367D98 !important;
}

.owl-theme .owl-dots .owl-dot span {
    background-color: #BBD2DB !important;
    transition: 0.6s;
}

.card-ticket-title .badge-color {
    background-color: #367D98 !important;
    border-radius: 50px;
    width: 28px;
    height: 28px;
}

.carouselCard-section .card .ticket-title {
    color: #201C1C;
}

.carouselCard-section .card .ticket-date {
    color: #A2BBCC;
    font-size: 14px;
    font-weight: 400;
}




.featuredImageContainer{
    height: 65%;
}
.featuredImageDiv img{
    object-fit: contain;
    height: 230px;
}
.textWrap{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}