.genreListSection ul {
    list-style: none;
    padding: 0px;
}

.genreListSection h5 {
    /* border-bottom: 2px solid var(--secondary-color); */
    background: var(--secondary-color);
    color: var(--default-color-white);
    font-size: 18px;
    font-weight: 600;
    padding: 12px 12px;
    margin-bottom: 30px;
}

.genreListSection ul li:nth-child(even) {
    color: var(--theme-color);
    font-size: 18px;
    font-weight: 500;
    padding: 8px 12px;
    margin-bottom: 12px;
}

.genreListSection ul li:nth-child(odd) {
    background: #bbddff;
    color: var(--theme-color);
    font-size: 18px;
    font-weight: 500;
    padding: 8px 12px;
    margin-bottom: 12px;
}

.genreListSection ul li:nth-child(even):hover {
    background: var(--theme-color);
    color: var(--default-color-white);
    cursor: pointer;
}

.genreListSection ul li:nth-child(odd):hover {
    background: var(--theme-color);
    color: var(--default-color-white);
    cursor: pointer;
}