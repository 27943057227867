.isList{
    padding-right: 34px   
}
.isList_btn{
    width: 49px 
}
.nav-scroller {
    position: relative;
    z-index: 2;
    height: auto;
    overflow-y: hidden;
}

.nav-scroller .nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    padding-bottom: 1rem;
    margin-top: -1px;
    overflow-x: auto;
    text-align: center;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    padding-top: 30px;
}

.nav-scroller .nav .dateInput {
    margin: 0px 10px 0px 20px;
    width: 175px;
}

.navScrollContainer {
    height: 100px;
    padding: 0px !important;
    max-width: 1200px !important;
}

.navBgColor {
    background: var(--theme-color);
}

.filterNav {
    background: #000;
    padding: 8px 50px !important;
    border-radius: 4px;
    color: var(--default-color-white);
    font-weight: 600;
    margin: 0px auto auto;
}

.searchResultFilter {
    display: flex;
    overflow-x: auto;
}

.collapsibleMobileModeSearch .Collapsible {
    background-color: white;
    display: none;
}

.collapsibleMobileModeSearch .Collapsible__contentInner {
    padding: 10px;
    border-bottom: 2px solid #ebebeb;
    border-top: 0;
}

.collapsibleMobileModeSearch .Collapsible__contentInner p {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px;
}

.collapsibleMobileModeSearch .Collapsible__contentInner p:last-child {
    margin-bottom: 0;
}

.collapsibleMobileModeSearch .Collapsible__trigger {
    display: block;
    font-weight: 400;
    text-decoration: none;
    color: #333333;
    position: relative;
    padding: 20px 10px;
    background: var(--theme-color);
    color: var(--default-color-white);
    cursor: pointer;
    font-weight: bold;
}

.collapsibleMobileModeSearch .Collapsible__trigger:after {
    font-family: 'FontAwesome';
    content: '\f107';
    position: absolute;
    right: 10px;
    top: 25px;
    display: block;
    transition: transform 300ms;
}

.collapsibleMobileModeSearch .Collapsible__trigger.is-open:after {
    transform: rotateZ(180deg);
}

.selectSearch {
    margin: 0px 10px;
}

.inputSearchSelect {
    margin: 15px 20px;
    color: var(--theme-color) !important;
}

.inputSearchSelect:focus {
    color: var(--theme-color) !important;
    border-color:#ced4da !important;
    box-shadow: none !important;
}

.desktopInputSearch {
    width: 175px !important;
    color: var(--theme-color) !important;
}

.desktopInputSearch:focus {
    border-color: #ced4da !important;
    box-shadow: none !important;
    color: var(--theme-color);
}

.selectSplict select {
    margin-bottom: 15px;
}

.selectSplict input {
    margin-bottom: 15px;
}

.mobileselectSection {
    width: 100%;
    display: contents;
}

.mobileselectSection .mobileInputSearch {
    margin: 10px 20px;
    color: var(--theme-color);
}

.mobileselectSection .mobileInputSearch:focus{
    border-color: #ced4da !important;
    box-shadow: none !important;
    color: var(--theme-color);
}

.mobileDateInput {
    width: 100%;
    margin: 10px 20px !important;
}

.mobileDateInput input {
    color: var(--theme-color);
}

.mobileDateInput input:focus {
    border-color: #ced4da !important;
    color: var(--theme-color);
    box-shadow: none !important;
}

.dateInput:focus {
    border-color: #ced4da !important;
    color: var(--theme-color);
    box-shadow: none !important;
}

.desktopInputLast{
    margin: 0px 10px 0px 10px;
    color: var(--theme-color);
}

.desktopInputLast input {
    width: 160px;
}

.desktopInputLast input:focus {
    border-color: #ced4da !important;
    color: var(--theme-color);
    box-shadow: none !important;
}

.searchResultTable {
    margin: 50px 0px !important;
}

.searchResultTable thead tr th {
    color: var(--default-color-white);
    background: var(--theme-color);
}

.ticketSection p {
    margin: 0px !important;
    font-size: 14px;
}

.ticketSection img {
    margin: 0px 15px;
    width: 54px;
    height: 84px;
}

.divSearchResult {
    margin: 3rem auto;
}

/* ---------------------------------------------- media query ---------------------------------------- */
@media only screen and (min-width: 300px) and (max-width: 768px) {
    .collapsibleMobileModeSearch .Collapsible {
        display: block !important;
    }
    .navBgColor {
        display: none;
    }
}