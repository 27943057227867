.sideBar-modal-dialog{
	width: 250px;
	height: 100%;
	-webkit-transform: translate3d(0%, 0, 0);
	-ms-transform: translate3d(0%, 0, 0);
	-o-transform: translate3d(0%, 0, 0);
	transform: translate3d(0%, 0, 0);
}
.sideBar-modal-content{
	overflow-y: auto;
}
.Offcan-logout {
	cursor:pointer;
}

.modal.left.fade .sideBar-modal-dialog {
	left: -250px;
	-webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
	-moz-transition: opacity 0.3s linear, left 0.3s ease-out;
	-o-transition: opacity 0.3s linear, left 0.3s ease-out;
	transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left.fade.show .sideBar-modal-dialog {
	left: 0;
}
