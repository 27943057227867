.mommentHistoryContent i {
    font-size: 18px;
    margin: 10px auto;
}

.mommentHistoryContent span {
    font-size: 14px;
    padding-right: 15px;
    padding-left: 15px;
}

.cardMomentHistory:hover {
    box-shadow: 0px 10px 20px rgb(0,0,0,.1);
    cursor: pointer;
    transition: box-shadow 500ms;
}

/* .columnTeamArt span{
    padding-left: 15px;  
}

.teamListSpan {
    padding-left: 15px;
    padding-right: 15px;
}

.eventLocationSpan {
    padding-left: 23px;
}

.eventDateSpan {
    padding-left: 15px;
} */
/* modified by DK */
/* .columnTeamArt{
    color: black;
} */

.mommentHistoryContent{
    color: black;
}

.mommentHistoryTitle h5{
    color: var(--theme-color);
}
/* modified by DK */