

.btnSearchBuyNow {
    background: var(--theme-color) !important;
    border-color: var(--theme-color) !important;
    padding: 6px 20px !important;
    font-weight: bold !important;
    font-size: 14px !important;
    color: var(--default-color-white) !important;
}

.btnSearchAddCart {
    background: var(--btn-blue-color) !important;
    border-color: var(--btn-blue-color) !important;
    padding: 6px 20px !important;
    font-weight: bold !important;
    font-size: 14px !important;
    color: var(--theme-color) !important;
}

.ticket_detail_image{
    object-fit: contain;
}

.button_parent {
    position: relative;
    left: 37px;
}
.responsiveView {
    display: none;
}
.desktop {
    display: block;
}
.objectfit {
  object-fit: contain;
  margin-bottom: 5px;
}
.objectfitDIV {
  object-fit: contain;
}
.parent_verticalOwlTickets body .area .content {
  width: 400px;
}
.parent_verticalOwlTickets .area {
  height: 500px;
  width: 100px;
  margin: 0px auto;
}
.parent_verticalOwlTickets .scrollarea .scrollbar-container {
  opacity: -1.9;
}
.parent_verticalOwlTickets  scrollbar-container vertical {
  opacity: -1.9;
}
.parent_verticalOwlTickets .scrollarea .scrollbar-container.active, .scrollarea .scrollbar-container:hover {
  opacity: -1.4 !important;
}
.parent_verticalOwlTickets .scrollarea .scrollbar-container.vertical .scrollbar:hover {
  opacity: -1.9;
}

.carouselTicketDetailsImage{
  height: 500px;
  object-fit: contain;
}
@media screen and (min-width: 320px) and (max-width: 768px ) {
    .parent_verticalOwlTickets {
        display: none;
    }
    .button_parent {
        left: 0%;
    }
    .responsiveView {
      display: block;
  }
  .desktop {
      display: none;
  }
}
.Zoom_img {
  cursor: pointer;
}
.parent_verticalOwlTickets .owl-carousel{
  transform: rotate(90deg);
  width: 258px; 
  margin-top: 115px;
  position: absolute;
  right: 0px;
} 
.parent_verticalOwlTickets .item{
  transform: rotate(-90deg);
  cursor: pointer;
}
.parent_verticalOwlTickets .owl-carousel .owl-nav{
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  top: calc(50% - 33px);
}
.parent_verticalOwlTickets div.owl-carousel .owl-nav .owl-prev {
  top:unset;
  bottom: 15px; 
}
.parent_verticalOwlTickets div.owl-carousel .owl-nav .owl-next{
  top:unset;
  bottom: 15px; 
}
.parent_verticalOwlTickets .owl-item active {
  width: 85px;
}