/*------------------------------------ React Custom Lodaer -------------------------------*/
/*------------------------------------ React Custom Lodaer -------------------------------*/
.loaderSection {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/*------------------------------------ React Bootstrap Spinner ----------------------------------*/
/* .spinnerSection div {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
} */