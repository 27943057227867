.listSectionHome ul {
    list-style: none;
    padding: 0px;
    width: 25rem;
}

.listSectionHome h5 {
    border-bottom: 2px solid var(--secondary-color);
    width: 25rem;
    font-size: 20px;
    font-weight: 600;
    padding: 15px 0px;
}

.listSectionHome ul a:nth-child(even) {
    background: #bbddff;
    border-radius: 4px;
    color: var(--secondary-color);
    font-size: 18px;
    font-weight: 500;
    padding: 8px 12px !important;
    margin-bottom: 12px;
    transition: 0.6s;
}

.listSectionHome ul a:nth-child(odd) {
    color: var(--secondary-color);
    border-radius: 4px;
    font-size: 18px;
    font-weight: 500;
    padding: 8px 12px !important;
    margin-bottom: 12px;
    transition: 0.6s;
}

.listSectionHome ul a:nth-child(even):hover {
    background: var(--theme-color);
    cursor: pointer;
}

.listSectionHome ul a:nth-child(odd):hover {
    background: var(--theme-color);
    cursor: pointer;
}

/* ---------------------------------------------- media query ---------------------------------------- */
@media only screen and (min-width: 300px) and (max-width: 768px) {
    .listSectionHome ul {
        width: 100%;
    }
    .listSectionHome h5 {
        width: 100%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 992px) { 
    .listSectionHome ul {
        width: 15rem;
    }
    .listSectionHome h5 {
        width: 15rem;
    }
}

@media only screen and (max-width: 1024px) {
    .listSectionHome ul {
        width: 15rem !important;
    }
    .listSectionHome h5 {
        width: 18rem !important;
    }
}

@media only screen and (max-width: 1280px) {
    .listSectionHome ul {
        width: 100% !important;
    }
    .listSectionHome h5 {
        width: 100% !important;
    }
}