.mobileDefaultSection {
    display: none;
}

@media only screen and (min-width: 300px) and (max-width: 768px) {
    .desktopDefaultSection {
        display: none;
    }
    .mobileDefaultSection {
        display: block;
    }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
    .desktopDefaultSection {
        display: none;
    }
    .mobileDefaultSection {
        display: block;
    }
}