.searchResultTitleDiv span {
    padding-right: 10px;
}

.searchResultTitleDiv span:nth-child(1) {
    font-size: 28px;
    font-weight: 600;
    color: var(--secondary-color);
}

.searchResultTitleDiv span:nth-child(2) {
    font-size: 25px;
}

.searchResultTitleDiv span:nth-child(3) {
    color: var(--theme-color);
    font-size: 18px;
    font-weight: bold;
}

.spanCost i, .spanSeller i {
    padding-right: 8px;
}
.ticketTypeClass{
    color: var(--theme-color);
    font-size: 18px;
    font-weight: bold;
}