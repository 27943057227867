.btnEditManageAddress, .btnDeleteManageAddress {
    background: var(--theme-color) !important;
    border-color: var(--theme-color) !important;
    padding: 6px 20px !important;
    font-weight: bold !important;
    font-size: 14px !important;
}

.btnEditManageAddress:focus, .btnDeleteManageAddress:focus {
    box-shadow: none !important;
    outline: none;
}