.order-payment-success{
    color: #1e7e34;
    letter-spacing: 1px;
    font-size: 18px;
    font-weight: bold;
}

.order-payment-cancelled{
    color: #FF0000;
    letter-spacing: 1px;
    font-size: 18px;
    font-weight: bold;
}