.product-links a {
    color: #A2BBCC;
    font-weight: 400;
}

.legal-links a {
    color: #A2BBCC;
    font-weight: 400;
}

.footer-content {
    color: #A2BBCC;
    font-weight: 400;
}

.copyright-text {
    font-weight: 400;
    color: #201C1C;
}

.link-sub-footer {
    display: flex;
    justify-content: flex-end;
}

.link-sub-footer a {
    color: #201C1C;
    font-weight: 400;
}
.social-media .nav-link .badge {
    background-color: #fff !important;
    border-radius: 50px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.social-media .nav-link {
    padding: 0 1rem 0 0 !important;
}
.headerNavBarActive {
    color:#207490 !important;
    font-weight: 600 !important;
  }
@media only screen and (min-width: 300px) and (max-width: 768px) {
    .copyright-text {
        text-align: center;
    }

    .link-sub-footer {
        justify-content: center;
    }
}

