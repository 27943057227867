.navFilterSection {
    padding: 23px 0px;
}

.navTeamArtistBgColor {
    background: var(--theme-color);
}

.selectTeam {
    padding: 8px 35px;
    font-weight: 600;
    background: var(--default-color-white);
    color: var(--theme-color) !important;
    border-color: var(--default-color-white);
}

.selectTeam:focus {
    box-shadow: none !important;
    border-color: var(--default-color-white);
}

.listSection ul {
    list-style: none;
    padding: 0px;
    width: 23rem;
}

.listSection ul a:nth-child(even) {
    color: var(--theme-color);
    font-size: 16px;
    font-weight: 500;
    padding: 8px 12px !important;
    text-align: left;
    margin-bottom: 20px;
    transition: 0.6s;
    letter-spacing: 2px;
}

.listSection ul a:nth-child(odd) {
    background: #bbddff;
    color: var(--theme-color);
    font-size: 16px;
    font-weight: 500;
    padding: 8px 12px !important;
    text-align: left;
    margin-bottom: 20px;
    transition: 0.6s;
    letter-spacing: 2px;
}

.listGridSection {
    margin: 50px auto;
    max-width: 1200px !important;
}

.listSection ul a:nth-child(even):hover {
    background: var(--theme-color);
    color: #fff;
    letter-spacing: 4px;
}

.listSection ul a:nth-child(odd):hover {
    background: var(--theme-color);
    color: #fff;
    letter-spacing: 4px;
}

.collapsibleMobileModeTeam .Collapsible {
    background-color: white;
    display: none;
}

.collapsibleMobileModeTeam .Collapsible__contentInner {
    padding: 10px;
    border-bottom: 2px solid #ebebeb;
    border-top: 0;
}

.collapsibleMobileModeTeam .Collapsible__contentInner p {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px;
}

.collapsibleMobileModeTeam .Collapsible__contentInner p:last-child {
    margin-bottom: 0;
}

.collapsibleMobileModeTeam .Collapsible__trigger {
    display: block;
    font-weight: 400;
    text-decoration: none;
    color: #333333;
    position: relative;
    padding: 20px 10px;
    background: var(--theme-color);
    color: var(--default-color-white);
    cursor: pointer;
    font-weight: 600;
}

.collapsibleMobileModeTeam .Collapsible__trigger:after {
    font-family: 'FontAwesome';
    content: '\f107';
    position: absolute;
    right: 10px;
    top: 25px;
    display: block;
    transition: transform 300ms;
}

.collapsibleMobileModeTeam .Collapsible__trigger.is-open:after {
    transform: rotateZ(180deg);
}

.navMenuFullWidth {
    width: 100%;
}

.divFilterTeamArtist {
    margin: 3.5rem auto;
}

/* ---------------------------------------------- media query ---------------------------------------- */
@media only screen and (min-width: 300px) and (max-width: 768px) {

    .collapsibleMobileModeTeam .Collapsible {
        display: block !important;
    }
    .navTeamArtistBgColor {
        display: none;
    }
}

