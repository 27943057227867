.banner-image img {
  max-width: 100%;
  min-height: auto;
  width: 100%;
}

.banner-section {
  position: relative;
}

.banner-overlay {
  /* background-image: linear-gradient(240deg, rgba(31, 115, 144, 40%), rgba(255, 187, 239)); */
  background-image: linear-gradient(80deg, rgba(255,187,239,1) 0%, rgba(31,115,144,40%) 70%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  height: 100%;
}

.banner-content {
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  z-index: 1;
}

.banner-content .banner-container span {
  /* background-image: linear-gradient(240deg, rgba(31, 115, 144, 40%), rgba(255, 187, 239)); */
  background-image: linear-gradient(80deg, rgba(255,187,239,1) 0%, rgba(31,115,144,40%) 70%);
  border-radius: 50px;
  padding: 5px 12px;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 40%);
  color: #fff;
  font-weight: 400;
}

.banner-container .banner-left-content h1 {
  color: #fff;
  padding-right: 5rem;
  font-size: 34px;
  margin-top: 1rem;
  line-height: 1.5;
  font-weight: 800;
}

.banner-container .banner-right-content {
  background-color: rgba(179,199,213,66%);
  border-radius: 12px;
  padding: 1rem;
  color: #fff;
}

.banner-container .banner-right-content h4 {
  font-size: 1.4rem;
  font-weight: 600;
}

.banner-container .banner-right-content p {
  font-weight: 400;
}

@media only screen and (min-width: 300px) and (max-width: 767px) {
  .banner-container .banner-right-content {
      display: none;
  }

  .banner-content .banner-container span {
      display: none;
  }

  .banner-container .banner-left-content h1 {
      font-size: 1.5rem;
      margin-top: 0;
      line-height: 1.5;
      font-weight: 600;
      text-align: center;
      padding: 0;
  }

  .banner-content {
      top: 40px;
      left: 0;
  }

  .banner-image img {
      min-height: 200px;
  }
  .banner-image  {
    max-height: 300px;
}
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-container .banner-left-content h1 {
      text-align: left;
      padding-top: 1rem;
  }
  .banner-container .banner-right-content {
      display: block;
      padding: 0.5rem;
  }
  .banner-container .banner-right-content h4 {
      font-size: 0.8rem;
  }
  .banner-container .banner-right-content p {
      font-size: 12px;
  }
  .banner-image img {
      min-height: 230px !important;
  }
  .banner-image  {
    max-height: 300px;
}
}

@media only screen and (min-width: 768px) and (max-width:1024px) {
  .banner-container .banner-left-content h1 {
      font-size: 22px;
  }

  .banner-container .banner-right-content h4 {
      font-size: 0.8rem;
  }

  .banner-container .banner-right-content p {
      font-size: 0.6rem;
  }

  .banner-content {
      top: 50px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1199px) {
  .banner-container .banner-left-content h1 {
      font-size: 36px;
  }

  .banner-container .banner-right-content h4 {
      font-size: 1.2rem;
  }
}

