/* .forgotPageSection {
    background: var(--secondary-color);
    height: 100vh;
    position: relative;
}
.btnReset {
    background: var(--theme-color) !important;
    border-color: var(--theme-color) !important;
    width: 100%;
}
.forgotPassd a, .forgotPassd a:hover {
    font-size: 1rem;
    color: var(--secondary-color);
}
.forgotFormSection {
    width: 26rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--default-color-white);
    padding: 26px;
    box-shadow: 0px 0px 20px 0px rgba(73, 73, 73, 0.4);
    -webkit-box-shadow: 0px 0px 20px 0px rgba(73, 73, 73, 0.4);
    -moz-box-shadow: 0px 0px 20px 0px rgba(73, 73, 73, 0.4);
    border-radius: 4px;
    border: 10px solid #f0f0f0;
}
.forgotTitleDiv h5 {
    margin: 0px;
    font-size: 1rem;
    font-weight: 600;
    color: var(--theme-color);
}

.loginLogo img {
    width: 100px;
}*/

.cardVerifySent {
    background: var(--secondary-color);
    height: 100vh;
    position: relative;
} 

.emailVerify {
    width: 26rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--default-color-white);
    padding: 26px;
    box-shadow: 0px 0px 20px 0px rgb(73 73 73 / 40%);
    -webkit-box-shadow: 0px 0px 20px 0px rgb(73 73 73 / 40%);
    -moz-box-shadow: 0px 0px 20px 0px rgba(73, 73, 73, 0.4);
    border-radius: 4px;
    border: 10px solid #eee;
}

.emailVerifyLogo img {
    width: 100px;
}

/* ---------------------------------------------- media query ---------------------------------------- */
@media only screen and (min-width: 300px) and (max-width: 768px) {
    .emailVerify {
        width: 90%;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .emailVerify {
        width: 90%;
    }
}