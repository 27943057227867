.sell-with-us {
    position: relative;
}

.overlay {
    background-image: linear-gradient(80deg, rgba(255,187,239,1) 0%, rgba(31,115,144,40%) 70%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    height: 100%;
}

.content-sellWithUs {
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    z-index: 1;
    padding: 0;
}

.content-sellWithUs h1 {
    font-size: 5rem;
    color: #fff;
}

.content-sellWithUs p {
    color: #201C1C;
    margin: 0 4rem;
    font-size: 22px;
}

.content-sellWithUs .btn-signUp {
    background-color: #207490;
    border-color: #207490;
    transition: 0.6s;
}

.content-sellWithUs .btn-signUp:focus {
    box-shadow: 0 0 0 0.2rem rgb(32 116 144 / 50%);
}

.content-sellWithUs .btn-signup:active {
    background-color: #207490 !important;
    border-color: #207490 !important;
    box-shadow: 0 0 0 0.2rem rgb(32 116 144 / 25%);
}

.content-sellWithUs .btn-signup:hover {
    background-color: #208baf !important;
    border-color: #208baf !important;
}

@media only screen and (min-width: 300px) and (max-width: 768px) {
    .content-sellWithUs .btn-signUp {
        margin-top: 1rem !important;
    }

    .content-sellWithUs h1 {
        font-size: 2rem;
    }

    .content-sellWithUs p {
        font-size: 12px;
    }

    .content-sellWithUs {
        top: 10px;
        left: 0;
        text-align: center;
    }

    .banner-image img {
        min-height: 150px;
    }
}