.filterNavTeam {
    background: #000;
    padding: 9px 25px;
    border-radius: 4px;
    color: var(--default-color-white);
    font-weight: 600;
}

/* Media query */
@media only screen and (max-width: 1280px) {
    .filterNavTeam {
        padding: 9px 10px;
    }
}