.tableTeamArtist thead {
    background: var(--theme-color);
    color: var(--default-color-white);
}

.tablePosition {
    margin: 3rem 0rem;
}

.tableTeamArtist thead tr th {
    padding: 1.2rem .75rem;
}

.nav-scroller {
    position: relative;
    z-index: 2;
    height: auto;
    overflow-y: hidden;
}

.nav-scroller .nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    padding-bottom: 1rem;
    margin-top: -1px;
    overflow-x: auto;
    text-align: center;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    padding-top: 30px;
}

.nav-scroller .nav .SelectControl {
    margin: 0px 15px;
    width: 200px;
}

.navScrollContainer {
    height: 100px;
    padding: 0px !important;
    max-width: 1200px !important;
}

.navBgColor {
    background: var(--theme-color);
}

.filterNav {
    background: #000;
    padding: 9px 50px;
    border-radius: 4px;
    color: var(--default-color-white);
    font-weight: 600;
}

/* .SelectControl {
    padding: 8px 35px;
    font-weight: 600;
    background: var(--default-color-white);
    color: var(--theme-color) !important;
    border-color: var(--default-color-white);
}

.SelectControl:focus {
    box-shadow: none !important;
    border-color: var(--default-color-white);
} */

/* .listSection {
    color: var(--theme-color);
    font-size: 16px;
    font-weight: 500;
    padding: 15px 12px !important;
    text-align: left;
    transition: 0.6s;
    letter-spacing: 2px;
    background: var(--btn-blue-color);
}

.listGridSection {
    margin: 50px auto;
    max-width: 1200px !important;
}

.listSection:nth-child(even):hover {
    background: var(--theme-color);
    color: #fff;
    letter-spacing: 4px;
}

.listSection:nth-child(odd):hover {
    background: var(--theme-color);
    color: #fff;
    letter-spacing: 4px;
} */

.btnTeamArtSubmit {
    background: var(--secondary-color) !important;
    border-color: var(--secondary-color) !important;
}

/* .listSectionTitle {
    border-bottom: 2px solid var(--secondary-color);
}

.listSectionTitle h5 {
    margin-bottom: 15px;
    text-align: center;
    font-weight: 600;
} */



/* Copied from genre component and renamed to teamArtistSection */
.teamArtistListSection ul {
    list-style: none;
    padding: 0px;
}

.teamArtistListSection h5 {
    /* border-bottom: 2px solid var(--secondary-color); */
    background: var(--secondary-color);
    color: var(--default-color-white);
    font-size: 18px;
    font-weight: 600;
    padding: 12px 12px;
    margin-bottom: 30px;
}

.teamArtistListSection ul li:nth-child(even) {
    color: var(--theme-color);
    font-size: 18px;
    font-weight: 500;
    padding: 8px 12px;
    margin-bottom: 12px;
}

.teamArtistListSection ul li:nth-child(odd) {
    background: #bbddff;
    color: var(--theme-color);
    font-size: 18px;
    font-weight: 500;
    padding: 8px 12px;
    margin-bottom: 12px;
}

.teamArtistListSection ul li:nth-child(even):hover {
    background: var(--theme-color);
    color: var(--default-color-white);
    cursor: pointer;
}

.teamArtistListSection ul li:nth-child(odd):hover {
    background: var(--theme-color);
    color: var(--default-color-white);
    cursor: pointer;
}

/* Copied from genre component and renamed to teamArtistSection */