.collapsibleMobile .Collapsible__trigger {
    display: block;
    font-weight: 400;
    text-decoration: none;
    color: #333333;
    position: relative;
    padding: 15px 10px;
    background: var(--secondary-color);
    color: var(--default-color-white);
    cursor: pointer;
    font-weight: 600;
    margin: 10px 0px;
}

.collapsibleMobile .Collapsible__trigger::after {
    font-family: 'FontAwesome';
    content: '\f107';
    position: absolute;
    right: 10px;
    top: 15px;
    display: block;
    transition: transform 300ms;
}

.collapsibleMobile .Collapsible__trigger.is-open:after {
    transform: rotateZ(180deg);
}