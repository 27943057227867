.btnClickSeller, .btnModelSubmit {
    background: var(--theme-color) !important;
    border-color: var(--theme-color) !important;
    padding: 6px 20px !important;
    font-weight: bold !important;
    font-size: 14px !important;
}

.btnClickSeller:focus, .btnModelSubmit:focus {
    outline: none;
    box-shadow: none !important;
}

.btnClickSeller:focus {
    box-shadow: none !important;
    border-color: var(--theme-color);
}

.topDividerStyle {
    margin: 1.5rem 0rem auto !important;
}

.modelAgreement {
    text-decoration: underline;
    color: var(--theme-color);
    letter-spacing: 1px;
    cursor: pointer;
}

.modelAgreement:hover {
    letter-spacing: 2px;
    transition: 0.6s;
}

.btnModelClose {
    background: #f8f9fa !important;
    border-color: #f8f9fa !important;
    padding: 7px 25px !important;
    font-weight: bold !important;
    font-size: 14px !important;
    color: #000 !important;
}

.btnModelClose:focus {
    outline: none;
    box-shadow: none !important;
}

.sellerCardHeader {
    font-weight: 500;
}