.tabselect {
  height: 40px;
}
.tabselect .nav-link {
  color: var(--theme-color);
}
.tabselect .nav-link.active {
  color: rgb(252, 252, 252) !important;
  background-color: var(--secondary-color) !important;
}

.headerbar {
  padding-top: 6px !important;
  font-weight: bold !important;
}
