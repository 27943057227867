.btnEditSection, .btnAddManageAddress {
    background: var(--theme-color) !important;
    border-color: var(--theme-color) !important;
    padding: 6px 20px !important;
    font-weight: bold !important;
    font-size: 14px !important;
}

.btnEditSection:focus, .btnAddManageAddress:focus {
    box-shadow: none !important;
    outline: none;
}

.btnAddContactInfo, .btnEditContactInfo {
    background: var(--theme-color) !important;
    border-color: var(--theme-color) !important;
    padding: 6px 20px !important;
    font-weight: bold !important;
    font-size: 14px !important;
}

.btnAddContactInfo:focus, .btnEditContactInfo:focus {
    box-shadow: none !important;
    outline: none;
}

.columnInfoSection {
    border-right: 1px solid #ccc;
}

.expandManageAddress {
    color: var(--theme-color);
    cursor: pointer;
    font-size: 16px;
}
/* image resize */
.userImage{
    padding: 10px;
    display: inline-block;
    width: 300px;
    max-height: 300px;
}
.userImage > img{ 
    object-fit: contain;
}
.image-edit-btn{
    margin-left: 36px;
}

@media only screen and (min-width: 300px) and (max-width: 768px) {
    .columnInfoSection {
        border-right: none;
        border-bottom: 1px solid #ccc;
    }
    .Collapsible__contentInner {
        margin: 10px;
    }
}