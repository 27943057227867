.popular-team-art-section h2 {
    color: #207490;
}

.popular-team-art-section p {
    color: #A2BBCC;
    font-weight: 400;
    margin: 0;
}

.popular-team-art-section .card {
    margin-bottom: 1rem;
    min-height: 140px;
    border: none;
    background-color: #BBD2DB;
    border-radius: 12px;
}

.popular-team-art-section .card-body {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #201C1C;
}

.popular-team-art-section .card-body span {
    width: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}