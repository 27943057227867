.btnSubmitTeamArt {
    background: var(--secondary-color) !important;
    border-color: var(--secondary-color) !important;
    padding: 6px 20px !important;
    font-weight: bold !important;
    font-size: 14px !important;
}

.btnClearTeamArt {
    background: var(--secondary-color) !important;
    border-color: var(--secondary-color) !important;
    padding: 6px 20px !important;
    font-weight: bold !important;
    font-size: 14px !important;
}

/* Media query */
@media only screen and (max-width: 1280px) {
    .filterSubBtn {
        display: flex;
    }
}