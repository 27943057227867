.sendMessageIcon {
    color: white;
    background-color: var(--secondary-color);
    cursor: pointer;
}

.offerButton{
    background-color: var(--secondary-color);
}

.offerButton:hover, .sendMessageIcon:hover{
    background-color: var(--secondary-color);
    box-shadow: 0px 10px 20px rgb(0,0,0,.1);
    transition: box-shadow 500ms;
}

.offerButton:disabled,.sendMessageIcon:disabled{
    background-color: grey;
    border-color: grey;
}