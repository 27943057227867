.input-search .form-control {
  background-color: #EFF4F6 ;
  font-size: 10;
  /* width: 500px; */
  padding: 0 46px;
  height: 48px;
}
::placeholder {
  font-size: 0.91rem;
}
.input-search .form-control::placeholder {
  color: #A2BBCC;
}

.input-search {
  position: relative;
}

.search-icon {
  position: absolute;
  top: 10px;
  left: 16px;
}

.cart-icon {
  position: relative;
  margin-left: 3rem;
}

.cart-count {
  position: absolute;
  top: -10px;
  right: -5px;
}

.cart-count .item-count {
  border-radius: 100%;
  background-color: #BBD2DB !important;
}

.main-menu .nav-menu .nav-link {
  color: #A2BBCC;
  font-weight: 400;
  transition: 0.3s;
}
.header-divider{
  border-color: #207490 !important;
  border-top-width: 0.1rem !important;
}
.nav-menu .nav-link:hover {
  color: #207490 !important;
}

.column-top-header {
  display: flex;
  justify-content: right;
  align-items: center;
}
.avator-image {
  width: 35px;
  border-radius: 4px;
}

.headerNavBarActive {
  color:#207490 !important;
  font-weight: 600 !important;
}

@media only screen and (min-width: 300px) and (max-width: 767px) {
  .cart-icon {
      margin-left: 1rem;
  }

  .input-search-column {
      order: 2;
  }

  .column-top-header {
      position: absolute;
      top: 26px;
      order: 1;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .input-search-column {
      order: 0;
  }

  .column-top-header {
      position: inherit;
      top: 0;
      order: 0;
  }

  .siteLogo img {
      width: 120px;
      margin-top: 0.5rem;
  }
}