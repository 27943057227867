.ticketSection {
    display: flex;
}
.badge-color {
    background-color: #367D98 !important;
    border-radius: 50px;
    width: 28px;
    height: 28px;
}
.divMyTicketCard {
    padding: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin-right: 15px;
}

.ticketTitleSection h5 {
    font-size: 16px;
    font-weight: bold;
}

.ticketTitleSection div {
    color: #707070;
    font-size: 14px;
    padding: 10px 0px;
}

.divMyTicketCard img {
    width: 100%;
    margin: 0px;
    height: 105px;
}

.btnAddTicket {
    background: var(--theme-color) !important;
    border-color: var(--theme-color) !important;
    padding: 6px 12px !important;
    font-weight: bold !important;
    font-size: 14px !important;
}

.btnAddTicket:focus {
    outline: none;
    box-shadow: none !important;
}

.btnAddTicket span {
    padding-right: 10px;
    vertical-align: text-bottom;
}

.btnMoveSeller {
    background: var(--btn-blue-color) !important;
    border-color: var(--btn-blue-color) !important;
    color: var(--theme-color) !important;
    padding: 6px 12px !important;
    font-weight: bold !important;
    font-size: 14px !important;
    margin: 15px auto;
    margin-right: 10px;
    width: 92px;
}

.btnMoveSeller:focus {
    outline: none;
    box-shadow: none !important;
}

.btnMoveSeller span {
    padding-left: 10px;
}

.btnEdit {
    background: var(--btn-blue-color) !important;
    border-color: var(--btn-blue-color) !important;
    color: var(--theme-color) !important;
    padding: 6px 12px !important;
    font-weight: bold !important;
    font-size: 14px !important;
    margin-right: 10px;
    width: 92px;
    margin-bottom: 11px;
}

.btnEdit span {
    padding-right: 10px;
    vertical-align: text-bottom;
}

.btnEdit:focus {
    outline: none;
    box-shadow: none !important;
}

.btnDelete {
    background: var(--btn-danger-color) !important;
    border-color: var(--btn-danger-color) !important;
    padding: 6px 12px !important;
    font-weight: bold !important;
    font-size: 14px !important;
    margin-right: 10px;
    width: 92px;
}

.btnDelete span {
    padding-right: 10px;
    vertical-align: text-bottom;
}

.btnDelete:focus {
    outline: none;
    box-shadow: none !important;
}

.ticketEventHistory h5 {
    font-size: 16px;
}

.ticketEventHistory div {
    color: #707070;
    font-size: 14px;
    padding: 6px 0px;
}

.btnTicketAddSubmit {
    background: var(--theme-color) !important;
    border-color: var(--theme-color) !important;
    padding: 6px 20px !important;
    font-weight: bold !important;
    font-size: 14px !important;
}

.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
}

.typeBuyBadge {
    background: var(--theme-color);
    color: var(--default-color-white);
    padding: .5rem .7rem !important;
    cursor: pointer;
    margin-left: 10px;
}

.statusBadge {
    background: #EEEEEE;
    color: var(--secondary-color);
    border-radius: 100px !important;
    cursor: pointer;
    padding: .5rem 2rem !important;
    font-weight: bold !important;
    font-size: 14px !important;
    margin-right: 22px;
}
.ticketType {
    font-size: 14px;
    font-weight: 600;
}

.ticketStatus {
    font-size: 14px;
    font-weight: 600;
}

@media screen and (min-width: 320px) and  (max-width: 768px ) {
    .btnEdit {
        background: var(--btn-blue-color) !important;
        border-color: var(--btn-blue-color) !important;
        color: var(--theme-color) !important;
        padding: 6px 12px !important;
        font-weight: bold !important;
        font-size: 11px !important;
        margin-right: 10px;
        min-height: -webkit-fill-available;
    }
    .btnDiv {
        display: flex;
    }
    .responsiveClass {
        margin-top: 17px;
        height: 43px;
    }
    .btnMoveSeller {
        background: var(--btn-blue-color) !important;
        border-color: var(--btn-blue-color) !important;
        color: var(--theme-color) !important;
        padding: 6px 12px !important;
        font-weight: bold !important;
        font-size: 11px !important;
        margin: 15px auto;
        margin-right: 10px;
        width: 92px;
    }
    .btnDelete {
        background: var(--btn-danger-color) !important;
        border-color: var(--btn-danger-color) !important;
        padding: 6px 12px !important;
        font-weight: bold !important;
        font-size: 11px !important;
        margin-right: 10px;
        width: 92px;
        min-height: -webkit-fill-available;
    }
  }

