.alignstart{
    padding-left: 48px;
}
.alignend{
    padding-right: 48px;
}
.alignstartbutton{
    float: right;
}

.invalid-feedback-display {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}